
.img-list-size {
    width: 4em;
    height: 4em;
    display: inline-block;
    border-radius: 1em;
  }

  .icons-principal-size {
    width: 60px;   /* 4.3em */
    height: 60px;
    display: inline-block;
    border-radius: 1em;
    box-sizing: border-box;
  }
  /*Ajusta el tamaño en función del ancho de página*/
  @media (min-width: 360px) {
    .icons-principal-size {
      width: calc(60px + (100vw - 360px) * (170 - 60) / (1200 - 360)); /* Tamaño del icono para pantallas medianas */
      height: calc(60px + (100vw - 360px) * (170 - 60) / (1200 - 360)); /* Tamaño del icono para pantallas medianas */
    }
  }
  @media (min-width: 1200px) {
    .icons-principal-size {
      width: 170px; /* Tamaño máximo del icono para pantallas grandes */
      height: 170px; /* Tamaño máximo del icono para pantallas grandes */
    }
  }

  .input-full-cell {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    background: none;
    padding: 0;
    margin: 0;
  }

  .ui.celled.table.cell-space {
    padding: 0.4em;
  }

  .sticky-segment {
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    height: 50px;
    width: 100%;
    z-index: 999;
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0px;
  }

  .button-grid {
    padding-left: 0;
    padding-right: 0;
  }
